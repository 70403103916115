{
  "_from": "web3@^1.7.1",
  "_id": "web3@1.7.1",
  "_inBundle": false,
  "_integrity": "sha512-RKVdyZ5FuVEykj62C1o2tc0teJciSOh61jpVB9yb344dBHO3ZV4XPPP24s/PPqIMXmVFN00g2GD9M/v1SoHO/A==",
  "_location": "/web3",
  "_phantomChildren": {},
  "_requested": {
    "type": "range",
    "registry": true,
    "raw": "web3@^1.7.1",
    "name": "web3",
    "escapedName": "web3",
    "rawSpec": "^1.7.1",
    "saveSpec": null,
    "fetchSpec": "^1.7.1"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/web3/-/web3-1.7.1.tgz",
  "_shasum": "4d01371a2c0c07dba089f8009dabd2b11821c5e8",
  "_spec": "web3@^1.7.1",
  "_where": "/web/apps/etherboard/frontend",
  "author": {
    "name": "ethereum.org"
  },
  "authors": [
    {
      "name": "Fabian Vogelsteller",
      "email": "fabian@ethereum.org",
      "homepage": "http://frozeman.de"
    },
    {
      "name": "Marek Kotewicz",
      "email": "marek@parity.io",
      "url": "https://github.com/debris"
    },
    {
      "name": "Marian Oancea",
      "url": "https://github.com/cubedro"
    },
    {
      "name": "Gav Wood",
      "email": "g@parity.io",
      "homepage": "http://gavwood.com"
    },
    {
      "name": "Jeffery Wilcke",
      "email": "jeffrey.wilcke@ethereum.org",
      "url": "https://github.com/obscuren"
    }
  ],
  "bugs": {
    "url": "https://github.com/ethereum/web3.js/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "web3-bzz": "1.7.1",
    "web3-core": "1.7.1",
    "web3-eth": "1.7.1",
    "web3-eth-personal": "1.7.1",
    "web3-net": "1.7.1",
    "web3-shh": "1.7.1",
    "web3-utils": "1.7.1"
  },
  "deprecated": false,
  "description": "Ethereum JavaScript API",
  "devDependencies": {
    "@types/node": "^12.12.6",
    "dtslint": "^3.4.1",
    "typescript": "^3.9.5",
    "web3-core-helpers": "1.7.1"
  },
  "engines": {
    "node": ">=8.0.0"
  },
  "gitHead": "75f7b803b40747095d0fd058d55a3d89367bb859",
  "homepage": "https://github.com/ethereum/web3.js#readme",
  "keywords": [
    "Ethereum",
    "JavaScript",
    "API"
  ],
  "license": "LGPL-3.0",
  "main": "lib/index.js",
  "name": "web3",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/ethereum/web3.js.git"
  },
  "scripts": {
    "compile": "tsc -b tsconfig.json",
    "dtslint": "dtslint --localTs ../../node_modules/typescript/lib types",
    "postinstall": "echo \"WARNING: the web3-shh and web3-bzz api will be deprecated in the next version\""
  },
  "types": "types/index.d.ts",
  "version": "1.7.1"
}
