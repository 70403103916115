/* jshint esversion:8 */
/* jslint es8 */
/* global process */

import EtherBoard from "../../contract/build/contracts/Etherboard.json";
import Web3 from "web3";
import ipfsClient from "ipfs-http-client";
window.ipfsClient = ipfsClient;
const messageEl = document.getElementById("message");
const infoEl = document.getElementById("info");

const notyf = new Notyf({
  duration: 0, ripple: false,
  types: [
    {
      type: 'info',
      background: 'blue',
      icon: false
    }
  ]
});

notyf.info = (message) => notyf.open({
  type: 'info', message: message
});


window.notyf = notyf;

let contract;
let web3;
let isScrolling;
let accounts = [];

let autoPriceIncreasePercent;
let commissionPercent;
let numOfSlots;
let firstMinSalePrice;
let messageParaEl;

function isValidHttpUrl(string) {
  let url;
  try { url = new URL(string); } catch (_) { return false; }
  return url.protocol === "http:" || url.protocol === "https:";
}

function calcCoveredHeight(el) {

  // Generic function to calculate element's covered height by using it's
  // height, padding, border and margin thicknesses.

  let computedStyle = document.defaultView.getComputedStyle(el);
  let height = parseInt(computedStyle.getPropertyValue("height")) || 0;
  let border = parseInt(computedStyle.getPropertyValue("border-top")) || 0 + parseInt(computedStyle.getPropertyValue("border-bottom")) || 0;
  let margin = parseInt(computedStyle.getPropertyValue("margin-top")) || 0 + parseInt(computedStyle.getPropertyValue("margin-bottom")) || 0;
  return height + border + margin;
}

function calcSaleReturnForOwner(currentPrice) {
  currentPrice = new Number(web3.utils.fromWei(currentPrice, 'ether'));
  let newPrice = currentPrice + (currentPrice / 100 * autoPriceIncreasePercent);
  let commission = newPrice / 100 * commissionPercent;
  let ownerReturn = newPrice - commission;
  return ownerReturn;
}

function cid2Url(cid) {
	return "https://ipfs.io/ipfs/" + cid;
}

async function initialize() {
  web3 = window.ethereum ? await new Web3(window.ethereum) : await new Web3(new Web3.providers.HttpProvider(process.env.API_URL));
  window.web3 = web3;
  let chainId = await web3.eth.getChainId();
  if (chainId !== Number(process.env.EXPECTED_CHAIN_ID)) {
    messageParaEl = document.createElement("p");
    messageParaEl.innerHTML = "You're on wrong Ethereum network. Switch to XDAI network and refresh this page.";
    messageEl.appendChild(messageParaEl);
    messageEl.style.display = "inherit";
    throw("EXPECTED_CHAIN_ID: " + process.env.EXPECTED_CHAIN_ID + ", Returned Chain ID:" + chainId);
  }

  contract = new web3.eth.Contract(EtherBoard.abi, process.env.CONTRACT_ADDRESS, {handleRevert: true});
  window.contract = contract;
  firstMinSalePrice = web3.utils.toBN(await contract.methods.firstMinSalePrice.call().call());
  commissionPercent = web3.utils.toBN(await contract.methods.commissionPercent.call().call());
  autoPriceIncreasePercent = web3.utils.toBN(await contract.methods.autoPriceIncreasePercent.call().call());
  numOfSlots = web3.utils.toBN(await contract.methods.numOfSlots.call().call());

  if (!window.ethereum) {
    messageParaEl = document.createElement("p");
    messageParaEl.innerHTML = "You need to install <a rel=\"noopener\" target=\"_blank\" href=\"https://metamask.io/download.html\">Metamask browser extension</a> to run this blockchain app properly.";
    messageEl.appendChild(messageParaEl);
    messageEl.style.display = "inherit";
  }
  initializeBoard();
}

function initializeBoard() {
  let bodyEl = document.getElementsByTagName("body")[0];
  let boardEl = document.getElementById("board");
  let overlayEl = document.getElementById("overlay");

  // Slots information will be cached here to avoid multiple contract calls.
  let slotCache = {};

  // Padding of slot element when it's selected.
  const selectedSlotPadding = 10;

  // Selected slot element.
  let selectedSlotEl = null;
  let buyFormEl = null;

  // 
  let renderedSlotIndexes = [];
  function slotElIndex(el) {
    // Parse index of slot element from it"s id.
    var indexStr = el.id.split("_")[1];
    return parseInt(indexStr);
  }

  function calcClosedSlotPosition(idx, coveredSlotHeight) {
    return idx * coveredSlotHeight;
  }

  function unselect(slotEl) {
    // Resize and reposition slot element to it"s open state. Also set
    // selected slot as clicked slot.
    slotEl.style.zIndex = 0;
    let idx = slotElIndex(slotEl);
    let drawerEl = slotEl.getElementsByClassName("drawer")[0];
    let coveredSlotHeight = getSlotDimensions()[1];
    slotEl.style.padding = 0;
    slotEl.style.left = 0;
    slotEl.style.top = calcClosedSlotPosition(idx, coveredSlotHeight) + "px";
    drawerEl.style.height = 0;
    overlayEl.style.display = "none";
  }

  function select(slotEl) {
    // Resize and reposition slot element to it"s open state. Also set
    // selected slot as clicked slot.
    slotEl.style.zIndex = 1;
    let idx = slotElIndex(slotEl);
    let drawerEl = slotEl.getElementsByClassName("drawer")[0];
    let targetDrawerHeight = calcCoveredHeight(drawerEl.getElementsByClassName("content")[0]);
    let coveredSlotHeight = getSlotDimensions()[1];
    drawerEl.style.height = targetDrawerHeight + "px";
    slotEl.style.padding = selectedSlotPadding + "px";
    slotEl.style.left = -selectedSlotPadding + "px";
    slotEl.style.top = calcClosedSlotPosition(idx, coveredSlotHeight) - (selectedSlotPadding) + "px";
    overlayEl.style.display = "block";
    selectedSlotEl = slotEl;
  }

  async function imgClickedHandler(ev) {

    // When image clicked, slot will be switched between open and closed
    // state.
    let clickedSlotEl = ev.target.parentElement;

    if (selectedSlotEl) {
      unselect(selectedSlotEl);
      selectedSlotEl = null;
    } else {
      select(clickedSlotEl);
      selectedSlotEl = clickedSlotEl;
    }
  }

  function getSlotDimensions() {
    // Draw an invisible slot get it's dimensions and destroy.
    let slotEl = document.createElement("div");
    let imgEl = document.createElement("img");
    slotEl.classList.add("slot");
    slotEl.style.visibility = "hidden";
    slotEl.appendChild(imgEl);
    boardEl.appendChild(slotEl);
    imgEl.style.height = imgEl.offsetWidth / 4 + "px";
    let result = [imgEl.offsetHeight, calcCoveredHeight(slotEl)];
    slotEl.remove();
    return result;
  }

  function buyButtonElClickedCallback(idx) {
    renderBuyForm(idx); 
  }
  function cancelButtonClickedCallback() {
    removeBuyForm();
  }
  async function getSlotInfo(idx) {
    if (slotCache[idx] === undefined) {
      let slotInfo = await contract.methods.getSlot(idx).call();
      // Convert them to big numbers.
      slotInfo.minSalePrice = web3.utils.toBN(slotInfo.minSalePrice);
      slotInfo.imgSrc = slotInfo.img ? cid2Url(slotInfo.img) : undefined
      slotInfo.saleReturn = calcSaleReturnForOwner(slotInfo.minSalePrice);
      slotCache[idx] = slotInfo;
    }
    return slotCache[idx];
  }

  async function fillSlotDetails(idxList) {
    idxList.forEach(async (idx) => {
      let slotInfo = await getSlotInfo(idx);
      let slotEl = document.getElementById("slot_" + idx);
      let imgEl = slotEl.getElementsByTagName("img")[0];
      let captionEl = slotEl.getElementsByClassName("caption")[0];
      let linkEl = captionEl.getElementsByTagName("a")[0];
      let buyButtonEl = slotEl.getElementsByTagName("button")[0];
      let profitInfoEl = slotEl.getElementsByClassName("profit_info")[0];
      if (slotInfo.imgSrc) {
        var img = new Image();
        img.onload = function() { imgEl.setAttribute("src", slotInfo.imgSrc); slotEl.classList.add("loaded"); };
        img.onerror = function() { slotEl.classList.add("loaded"); };
        img.src = slotInfo.imgSrc;
      } else {
        slotEl.classList.add("initial");
      }
      if (slotInfo.caption) {
        linkEl.innerText = slotInfo.caption;
        imgEl.alt = slotInfo.caption;
      } else {
        linkEl.parentElement.style.display = "none";
      }
      if (slotInfo.link) linkEl.setAttribute("href", slotInfo.link);
      buyButtonEl.innerText = "Buy for $" + web3.utils.fromWei(slotInfo.minSalePrice, "ether");
      profitInfoEl.innerText = "You will get $" + slotInfo.saleReturn + " when someone else buys.";
      buyButtonEl.addEventListener("click", buyButtonElClickedCallback.bind(null, idx));
    });
  }
  
  function renderVisibleSlots() {
    let [imgHeight, coveredSlotHeight] = getSlotDimensions();
    let numOfVisibleSlots = Math.min(Math.ceil(window.innerHeight / coveredSlotHeight), numOfSlots);
    let firstVisibleSlotIdx = Math.floor(Math.max((window.scrollY - boardEl.offsetTop) / coveredSlotHeight, 0));
    let result = [];
    boardEl.style.height = coveredSlotHeight * (numOfSlots + 2) + "px";
    for (var i=0; i < numOfVisibleSlots; i++) {
      let idx = firstVisibleSlotIdx + i;
      result.push(idx);
      if (document.getElementById("slot_" + idx)) continue;
  
      let slotEl = document.createElement("div");
      let imgEl = document.createElement("img");
      let drawerEl = document.createElement("div");
      let drawerContentEl = document.createElement("div");
      let capEl = document.createElement("p");
      let linkEl = document.createElement("a");
      let actionBarEl = document.createElement("div");
      let buyButtonEl = document.createElement("button");
      let profitInfoEl = document.createElement("p");

      slotEl.classList.add("slot");
      drawerEl.classList.add("drawer");
      drawerContentEl.classList.add("content");
      profitInfoEl.classList.add("profit_info");

      imgEl.style.height = imgHeight + "px";
      capEl.classList.add("caption");
      actionBarEl.classList.add("actions");
      buyButtonEl.classList.add("buy");

      slotEl.appendChild(imgEl);
      capEl.appendChild(linkEl);

      actionBarEl.appendChild(buyButtonEl);
      actionBarEl.appendChild(profitInfoEl);

      drawerContentEl.appendChild(capEl);
      drawerContentEl.appendChild(actionBarEl);
      
      drawerEl.appendChild(drawerContentEl);
      slotEl.appendChild(drawerEl);

      slotEl.setAttribute("id", "slot_" + idx);
      slotEl.style.top = calcClosedSlotPosition(idx, coveredSlotHeight) + "px";
      boardEl.appendChild(slotEl);
 
      imgEl.addEventListener("click", imgClickedHandler);
    }
    fillSlotDetails(result);
    return result;
  }

  renderedSlotIndexes = renderVisibleSlots();

  function removeBuyForm() {
    buyFormEl.remove();
    buyFormEl = null;
    bodyEl.style.height = "auto";
    bodyEl.style.overflowY = "auto";
    overlayEl.style.zIndex = 1;
  }
  
  async function validateBuyForm(imgFile, caption, link) {

    // * if image given:
    //   - must be 400x100 size.
    //   - mime type must be png.
    // * if link given
    //   - link must be a valid URL.
    //   - caption is mandotory.

    return new Promise(function(resolve, reject) {
      let img = new Image();
      let reader = new FileReader();
      let errors = {"img": [], "caption": [], "link": []};

      let error;
      if (imgFile) reader.readAsDataURL(imgFile);
      if (link && !caption) {
        error = document.createElement("li");
        error.innerText = "When link is given, caption must be given too.";
        errors.caption.push(error);
      }
      if (link && !isValidHttpUrl(link)) {
        error = document.createElement("li");
        error.innerText = "Link is not a valid HTTP / HTTPS URL.";
        errors.link.push(error);
      }
      img.onload = function() {
        if (img.naturalWidth !== 400) {
          error = document.createElement("li");
          error.innerText = "Selected image width " + img.naturalWidth + "px. Required width is 400px.";
          errors.img.push(error);
        }
        if (img.naturalHeight !== 100) {
          error = document.createElement("li");
          error.innerText = "Selected image height " + img.naturalHeight+ "px. Required height is 100px.";
          errors.img.push(error);
        }
        reject(errors);
      };
      reader.addEventListener("load", function () { img.src = reader.result; }, false);
    });
  }
  async function renderBuyForm(idx) {
    // I'm not using template engine because I'm a psycho.
    if (buyFormEl) { return; }

    let slotInfo = await getSlotInfo(idx);

    buyFormEl = document.createElement("form");

    let imgFieldEl = document.createElement("div");
    let imgLabelEl = document.createElement("label");
    let imgInputEl = document.createElement("input");
    let imgErrorsEl = document.createElement("ul");
    let imgDescEl = document.createElement("p");

    let captionField = document.createElement("div");
    let captionLabelEl = document.createElement("label");
    let captionInputEl = document.createElement("input");
    let captionErrorsEl = document.createElement("ul");
    let captionDescEl = document.createElement("p");

    let linkFieldEl = document.createElement("div");
    let linkLabelEl = document.createElement("label");
    let linkInputEl = document.createElement("input");
    let linkErrorsEl = document.createElement("ul");
    let linkDescEl = document.createElement("p");

    let priceFieldEl = document.createElement("div");
    let priceLabelEl = document.createElement("label");
    let priceInputEl = document.createElement("input");
    let priceDescEl = document.createElement("p");
    
    let actionFieldEl = document.createElement("div");
    let buyButtonEl = document.createElement("input");
    let buyButtonErrorEl = document.createElement("p");
    buyButtonErrorEl.innerHTML = "You need to install <a rel=\"noopener\" target=\"_blank\" href=\"https://metamask.io/download.html\">Metamask browser extension</a> to buy this slot.";
    buyButtonErrorEl.style.textAlign = "center";
    buyButtonErrorEl.style.color = "red";
    buyButtonErrorEl.style.display = "none";
    if (!window.ethereum) buyButtonErrorEl.style.display = "inherit";
    let cancelButton = document.createElement("button");

    imgInputEl.setAttribute("type", "file");
    imgInputEl.setAttribute("accept", "image/png, image/jpg, image/jpeg");
    imgLabelEl.innerText = "Image:";
    imgDescEl.innerText = "400x100 PNG file.";

    [imgErrorsEl, captionErrorsEl, linkErrorsEl].forEach((el) => el.classList.add("errors"));
    [imgLabelEl, imgInputEl, imgErrorsEl, imgDescEl].forEach((el) => imgFieldEl.appendChild(el));

    captionLabelEl.innerText = "Caption:";
    captionDescEl.innerText = "This text will be displayed under the image that you uploaded.";
    [captionLabelEl, captionInputEl, captionErrorsEl, captionDescEl].forEach((el) => captionField.appendChild(el));

    linkLabelEl.innerText = "Link:";
    linkDescEl.innerText = "When your caption is clicked, page will be redirected to given link.";
    [linkLabelEl, linkInputEl, linkErrorsEl, linkDescEl].forEach((el) => linkFieldEl.appendChild(el)); 

    priceLabelEl.innerText = "Price:";
    priceDescEl.innerText = "You will get " + slotInfo.saleReturn + " $ when someone else buys.";
    priceInputEl.setAttribute("value", web3.utils.fromWei(slotInfo.minSalePrice, 'ether'));
    priceInputEl.disabled = true;
    [priceLabelEl, priceInputEl, priceDescEl].forEach((el) => priceFieldEl.appendChild(el));

    buyButtonEl.setAttribute("type", "submit");
    buyButtonEl.setAttribute("value", "Buy");
  
    if (!window.ethereum) buyButtonEl.disabled = true;

    cancelButton.innerText = "Cancel";
    actionFieldEl.classList.add("actions");
    actionFieldEl.appendChild(cancelButton);
    actionFieldEl.appendChild(buyButtonEl);

    let fields = [imgFieldEl, captionField, linkFieldEl, priceFieldEl, actionFieldEl, buyButtonErrorEl];
    fields.forEach((el) => el.classList.add("field"));
    fields.forEach((el) => buyFormEl.appendChild(el));

    bodyEl.appendChild(buyFormEl);
    overlayEl.style.display = "block";
    overlayEl.style.zIndex = 2;
    buyFormEl.style.width = boardEl.offsetWidth + (selectedSlotPadding * 2) + "px";
    buyFormEl.style.left = boardEl.offsetLeft - selectedSlotPadding + "px";
    buyFormEl.style.top = (window.innerHeight - calcCoveredHeight(buyFormEl)) / 4 + "px";

    bodyEl.style.height = "100vh";
    bodyEl.style.overflowY = "hidden";

    buyFormEl.addEventListener("submit", async (event) => {
      event.preventDefault();
      let caption = captionInputEl.value;
      let link = linkInputEl.value;
      let imgFile = imgInputEl.files[0];
      captionInputEl.disabled = true;
      linkInputEl.disabled = true;
      buyButtonEl.disabled = true;
      cancelButton.disabled = true;
      let errors = null;
      await validateBuyForm(imgFile, caption, link).catch(formErrors => errors = formErrors);
      if (errors.img.length + errors.caption.length + errors.link.length === 0) {
        if (accounts.length === 0) {
          try {
            accounts = await web3.eth.requestAccounts();
          } catch(err) {
            notyf.error("Wallet connection error. Can not continue.");
          }
        }
        [imgErrorsEl, captionErrorsEl, linkErrorsEl].forEach((el) => el.innerHTML = "");
        let cid = "";
        if(imgFile){
          const ipfs = ipfsClient({
            host: "ipfs.infura.io", port: 5001, protocol: "https"
          });
          infoEl.innerText = "Uploading file";
          let upload = await ipfs.add(imgFile, {
            progress: (prog) => console.log(`received: ${prog}`) }
          );
          cid = upload.path;
        }

        const gas = await contract.methods.processPayment(idx, caption, link, cid)
          .estimateGas({ from: accounts[0], value: slotInfo.minSalePrice.toString()})
          .catch((err) => {
            let errData = JSON.parse(err.message.slice(25, err.message.length))
            notyf.error(errData.message);
            return null;
        });
        if (gas) {
          await contract.methods.processPayment(idx, caption, link, cid)
            .send({ from: accounts[0], value: slotInfo.minSalePrice.toString(), gas})
            .then(() => {
              notyf.success("You have bought Slot #" + idx + " for " + web3.utils.fromWei(slotInfo.minSalePrice, 'ether') + " $");
            })
            .catch((err) => {
              console.log(err.reason); 
              notyf.error("Error during processing payment");
            });
        }
        slotCache[idx] = undefined;
        removeBuyForm();
        unselect(selectedSlotEl);
        renderVisibleSlots();        
      } else {
        captionInputEl.disabled = false;
        linkInputEl.disabled = false;
        buyButtonEl.disabled = false;
        cancelButton.disabled = false;
        [imgErrorsEl, captionErrorsEl, linkErrorsEl].forEach((el) => el.innerHTML = "");
        errors.img.forEach((el) => imgErrorsEl.appendChild(el));
        errors.caption.forEach((el) => captionErrorsEl.appendChild(el));
        errors.link.forEach((el) => linkErrorsEl.appendChild(el));
      }
    });
    cancelButton.addEventListener("click", () => cancelButtonClickedCallback());
  }

  window.addEventListener("scroll", function () {
    window.clearTimeout(isScrolling);
    isScrolling = setTimeout(function() {
      let newRenderedSlotIndexes = renderVisibleSlots();
      let nonVisibleSlotIndexes = renderedSlotIndexes.filter( idx => !newRenderedSlotIndexes.includes(idx) );
      nonVisibleSlotIndexes.forEach(function(idx) {
        let slotEl = document.getElementById("slot_" + idx);
        if (slotEl === selectedSlotEl) { unselect(selectedSlotEl); selectedSlotEl = null; }
        slotEl.remove();
      });
      renderedSlotIndexes = newRenderedSlotIndexes;
    }, 100);
  }, false);

  window.addEventListener("resize", () => {
    boardEl.innerHTML = "";
    renderedSlotIndexes = renderVisibleSlots();
  });

  overlayEl.addEventListener("click", () => {
    if (buyFormEl !== null) {
      removeBuyForm();
    } else {
      unselect(selectedSlotEl);
      selectedSlotEl = null;
    }
  });
}
initialize();
