{
    "0xa2A6d93439144FFE4D27c9E088dCD8b783946263": "0xD3C21BCECCEDA1000000",
    "0xBc11295936Aa79d594139de1B2e12629414F3BDB": "0xD3C21BCECCEDA1000000",
    "0x7cF5b79bfe291A67AB02b393E456cCc4c266F753": "0xD3C21BCECCEDA1000000",
    "0xaaec86394441f915bce3e6ab399977e9906f3b69": "0xD3C21BCECCEDA1000000",
    "0xF47CaE1CF79ca6758Bfc787dbD21E6bdBe7112B8": "0xD3C21BCECCEDA1000000",
    "0xd7eDDB78ED295B3C9629240E8924fb8D8874ddD8": "0xD3C21BCECCEDA1000000",
    "0x8b7F0977Bb4f0fBE7076FA22bC24acA043583F5e": "0xD3C21BCECCEDA1000000",
    "0xe2e2659028143784d557bcec6ff3a0721048880a": "0xD3C21BCECCEDA1000000",
    "0xd9a5179f091d85051d3c982785efd1455cec8699": "0xD3C21BCECCEDA1000000",
    "0xbeef32ca5b9a198d27B4e02F4c70439fE60356Cf": "0xD3C21BCECCEDA1000000",
    "0x0000006916a87b82333f4245046623b23794c65c": "0x84595161401484A000000",
    "0xb21c33de1fab3fa15499c62b59fe0cc3250020d1": "0x52B7D2DCC80CD2E4000000",
    "0x10F5d45854e038071485AC9e402308cF80D2d2fE": "0x52B7D2DCC80CD2E4000000",
    "0xd7d76c58b3a519e9fA6Cc4D22dC017259BC49F1E": "0x52B7D2DCC80CD2E4000000",
    "0x799D329e5f583419167cD722962485926E338F4a": "0xDE0B6B3A7640000"
}
